@font-face {
  font-family: "SFSportsNight";
  src: url("../../assets/fonts/SFSportsNight.ttf");
}

.top-section {
  height: 66px;
  width: 100%;
  background: #15181e;
  display: flex;
  align-items: center;
  font-size: 11px;
  overflow-x: auto;
}

.top-section::-webkit-scrollbar {
  display: none;
}

.top-events {
  height: 30px;
  color: white;
  background: #292c2f;
  border-radius: 40px;
  width: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  white-space: nowrap;
  padding-left: 20px;
  padding-right: 20px;
}

.competition-short-name {
  /* height: 100%; */
  background: #292c2f;
  color: white;
  padding: 0 20px;
  display: flex;
  align-items: center;
}

.matches {
  background: #a39c9c;
  height: 100%;
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
}

.user-navigation-area {
  background: #346ca4;
  height: 44px;
  width: 100%;
  /* position: -webkit-sticky;
  position: sticky; */
  display: flex;
}

.main-logo {
  font-family: "SFSportsNight";
  color: white;
  background: #1d213f;
  font-size: 34px;
  line-height: 1;
  padding: 8px 4px 7px 11px;
  white-space: nowrap;
  display: flex;
  position: relative;
}

.main-logo:before {
  content: "";
  position: absolute;
  border-bottom: 44px solid #346ca4;
  border-left: 31px solid #1d213f;
  width: 44px;
  margin-top: -8px;
  right: -44px;
}

.blink-top {
  display: inline-block;
  animation: blinker 1s linear infinite;
  font-weight: 900;
  line-height: 0;
  color: red;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.navbar-links{
  margin-left: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 40%;
  font-weight: bold;
}

.right-navigation{
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 10px;
  padding-top: 10px;
}

.login-signup-modal{
  transform: 'translate(-50%, -50%)';
  width: 500px;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 25;
  margin: 50px auto auto;
}

.profile-modal{
  transform: 'translate(-50%, -50%)';
  width: 60%;
  background-color: white;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 25;
  margin: 50px auto auto;
}

.modal-title-section{
  display: flex;
}

.modal-title{
  margin: auto;
  font-family: "SFSportsNight";
  color:#A39E9E;
}

.dropdown {
  position: relative;
  bottom: 5px;
  display: inline-block;
  padding: 5px 4px 3px 0;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: -6px;
  top: 47px;
  background-color: #212529;
  min-width: 250px;
  min-height: 350px;
  box-shadow:0px 30px 40px 0px black;;
  padding: 12px 16px;
  z-index: 1;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content::before {
  content: "";
  display: block;
  position: absolute;
  top: -8px;
  right: 13px;   
  transform: translateX(-50%) rotate(45deg);
  width: 15px;
  height: 15px;
  background-color: #212529;
  border-bottom: none;
  border-right: none;
}

.dropdown-content .cs-button:hover{
  color: #212529 !important;
  background-color: #6ea9d7 !important;
  font-weight: bold;
  border-radius: 2px !important;
}

.dropdown-content .cs-button{
  text-align: left !important;
  display: block !important;
  width: 90px;
}

.premium-menu {
  height: 460px;
  width: 420px;
  background-color: rgb(44 48 52);
  border-radius: 3px;
  padding: 15px;
}

.premium-price {
  position:relative;
  height:120px;
  border: 1px solid white;
  border-radius: 3px;
  width: 188px;
  padding: 10px;
  overflow: hidden;
}

.premium-checkbox {
  width: 1em;
  height: 1em;
  background-color: inherit;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #ddd;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.premium-checkbox:checked {
  background-color: orangered;
  border-color: orangered;
  background-image: url('../../assets/images/✓.png');
  background-position: center;
  background-repeat: no-repeat;
}

.not-available {
  width: inherit;
  height: inherit;
  border-radius: inherit;
  background: #302c2cd1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
}

.subscribe-button {
  width: 100%;
  border-radius: 50px;
  height: 50px;
  background: orangered;
  color: white;
  border: 0;
  font-weight: bold;
  margin-top: 5px;
}


.payment-modal {
  margin: 50px auto auto;
  width:33vw;
  height: 60vh;
  background: white;
  border-radius: 7px;
}

.menu-icon{
  display: none !important;
}

@media (max-width: 767px) {
  .top-section{
    display: none;
  }
  .navbar-links{
    display: none;
  }
  .menu-icon{ 
    display: block !important;
  }
}

@media (max-width: 400px){
  .user-navigation-area{
    background: #1d213f;
  }
  .dropdown{
    display:none;
  }
  .main-logo:before{
    display: none;
  }
}


.css-o32dt4{
  height: 100vh !important;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
}