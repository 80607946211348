.login-signup-modal-content{
    padding-top: 30px;
}

.input-login-signup {
    width: 350px !important;
    /* height: 45px; */
    /* background: white !important; */
    /* border: 1px solid #A39E9E; */
    border-radius: 5px !important;
    margin-left: 50px !important;
    /* padding: 10px; */
    font-size: 18px !important;
    color: #333 !important;
    margin-bottom: 8px !important;
}

input.input-login-signup:focus {
    border: 2px solid #1a73e8;
    outline: none;
}


.login-signup-button{
    width: 350px;
    height: 45px;
    border-radius: 50px;
    background: #0066CC;
    border: none;
    color: white;
    margin-left: 50px;
    font-size: 18px;
}

.offscreen {
    position: absolute;
    left: -9999px;
}

.errmsg {
    background-color: lightpink;
    color: firebrick;
    font-weight: bold;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
}
  