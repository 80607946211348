.css-1cujig0{
    margin-left: 0 !important;
}

.browse-accordion{
    display: none !important;
}

.profile-accordion{
    display: none !important
}

@media (max-width: 767px) {
    .browse-accordion{
        display: block !important;
    }
}

@media (max-width: 400px) {
    .profile-accordion{
        display: block !important;
    }
}