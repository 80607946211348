.news-details {
    width: 60vw;
    border: 1px solid #292c2f;
    border-radius: 5px;
    color: white;
    height: 100%;
    min-height: 100vh;
    padding-top: 25px;
    display: grid;
}

.editor-block{
    padding-left:20px;
    padding-right:20px;
}

.tweet-block{
    display:flex;
    justify-content: center;
    align-items: center;
    padding: 0;
}

.iframe-component{
   min-width: 50%;
}
