.competitions-section {
  width: 17vw;
  border: 1px solid #292c2f;
  border-radius: 5px;
  color: white;
  padding: 10px;
  height: 100%;
  min-height: 100vh;
}

.competitions-search {
  display:flex;
  align-items: baseline;
}

@media (max-width:900px){
  .competitions-section{
      display: none;
  }
}

.list-logo {
  min-width: 12px;
  min-height: 12px;
  max-height: 18px;
  max-width: 24px;
  border-radius: 2px;
}

.search-input {
  margin-top: 14px;
  background: transparent;
  border: none;
  max-width: 80%;
  margin-left: 9px;
  flex:1;
}

.search-svg{
  min-width: 25px;

}

.list-names{
    white-space: nowrap;
    overflow-x:hidden;
    margin-left: 10px;
}

.list-container{
    display: flex;
    margin-bottom: 5px;
}
