.news-section {
  width: 25vw;
  border: 1px solid #292c2f;
  border-radius: 5px;
  color: white;
  padding: 10px;
  height: 100%;
}

@media (max-width:900px){
    .news-section{
        width: 30vw;
    }
}

@media (max-width:700px){
    .news-section{
        display: none;
    }   
}

.article{
    height: 170px;
    width: 100%;
    border-radius: 5px;
    margin-bottom: 20px;
    position: relative;
    box-shadow: inset 0px 0px 20px 20px rgba(0,0,0,0.75);
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.article-image{
    height:100%;
    width:100%;
    border-radius: 5px;
}


.article-title{
    text-align: center;
    font-weight: bold;
}