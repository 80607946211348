.favorites-section {
    width: 50vw;
    border: 1px solid #292c2f;
    border-radius: 5px;
    color: white;
    padding: 10px;
    /* margin: 20px; */
    min-height: 90vh;
}

@media (max-width:900px){
    .favorites-section{
      width:60vw;
    }
}
  
@media (max-width:700px){
    .favorites-section{
        width: 90vw;
    }
}

.competition {
    background: #292c2f;
    list-style: none;
    margin-bottom: 8px;
    padding: 10px;
    border-radius: 5px;
    display: flex;
  }
  
.competition:hover {
    /* opacity: 0.3; */
    background:inherit;
    border: 1px solid #fff;
}