.pitch {
    display: grid;
    border-radius: 5px;
    border: 1px solid #292c2f;
    margin-top: 15px;
    padding-bottom:10px;
    padding-top:10px;
    width:100%;
    height:464px;
    background-image:url('../../assets/images/footballpitch1.png');
    background-size: contain;
    background-clip: border-box;
    background-position: center;
    /* background-repeat-: no-repeat; */
}

.field {
    background:rgba(32, 31, 31, 0.589);
    height:398px;
    width:91%;
    margin-left: 31px;
    display:flex;
}

.home-team-player-icon {
    align-items: center;
    border-radius: 26px;
    font-size: .75rem;
    font-weight: 700;
    position: relative;
    width: 1.5rem;
    display: flex;
    height: 1.5rem;
    background: black;
    justify-content: center;
    margin:auto;
}

.away-team-player-icon {
    align-items: center;
    border-radius: 26px;
    font-size: .75rem;
    font-weight: 700;
    position: relative;
    width: 1.5rem;
    display: flex;
    height: 1.5rem;
    background: white;
    color:black;
    justify-content: center;
    margin:auto;
}

.groundsubout {
    display: block;
    max-height: 14px;
    min-width: 14px;
    position: absolute;
    left: 15px;
    top: 15px;
    z-index: 1;
}

.subouttime {
    display: block;
    font-size: 10px;
    color: #df2357;
    position: absolute;
    left: 30px;
    top: 15px;
    z-index: 1;
}

.yellowcard {
    display: block;
    max-height: 14px;
    min-width: 14px;
    position:absolute;
    left: 15px;
    bottom: 15px;
    z-index: 1;
}

.footballgoal {
    display: block;
    max-height: 14px;
    min-width: 14px;
    position:absolute;
    right: 15px;
    bottom: 15px;
    z-index: 1;
}

.goalcount {
    align-items: center;
    background-color: #a00000;
    border-radius: 9999px;
    color: #fdfdfd;
    display: flex;
    font-size: 10px;
    font-weight: 100;
    height: 12px;
    justify-content: center;
    width: 12px;
    position:absolute;
    right: 20px;
    bottom: 22px;
    z-index: 1;
}

.substitutions {
    display: grid;
    border-radius: 5px;
    border: 1px solid #292c2f;
    padding-top: 15px;
    padding-bottom: 15px;
}

.subplayericon {
    align-items: center;
    border-radius: 26px;
    font-size: .75rem;
    font-weight: 700;
    position: relative;
    width: 1.5rem;
    display: flex;
    height: 1.5rem;
    border: 1px solid white;
    justify-content: center;
    margin-left:15px;
}

.subinicon {
    display:block;
    height:14px;
    width:14px;
    position: absolute;
    left:15px;
    top: 15px
}

.subintime {
    display: block;
    font-size: 10px;
    color: #23df8c;
    position: absolute;
    left: 30px;
    top: 15px;
    z-index: 1;
}