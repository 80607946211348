.news-sect {
    width: 60vw;
    border: 1px solid #292c2f;
    border-radius: 5px;
    color: white;
    height: 100%;
    min-height: 500px;
}

@media (max-width:900px){
    .news-sect{
      width:80vw;
    }
}
  
@media (max-width:700px){
    .news-sect{
        width: 90vw;
    }
}

.news-title{
    width:100%;
    padding: 10px;
    border-bottom: 1px solid #292c2f;
    display: flex;
}

